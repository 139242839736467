import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { getList } from "../../request/api";

const Admin: React.FC = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    getList().then((res: any) => {
      setData(res?.data || []);
    });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.account}>账号</span>
        <span className={styles.time}>登录时间</span>
        <span className={styles.num}>关卡数</span>
      </div>
      <div className={styles.body}>
        {data.map((item, index) => (
          <div key={index} className={styles.row}>
            <span className={styles.account}>{item?.account}</span>
            <span className={styles.time}>{item?.loginTime || "未登录"}</span>
            <span className={styles.num}>{item?.count}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Admin;
