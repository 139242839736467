import { Toast } from "antd-mobile";
import { makeObservable, observable } from "mobx";
import { login } from "../request/api";

class AuthStore {
  curDaka: number = -1;

  constructor() {
    makeObservable(this, {
      curDaka: observable,
    });
  }

  setCurDaka(v: number) {
    this.curDaka = v;
  }
}

export default new AuthStore();
