import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import AuthStore from "../../store/AuthStore";
import { useNavigate } from "react-router";
import { Dialog, Switch, Toast } from "antd-mobile";
import { getPublicPath, joinClassNames } from "../../util";
import { checkToken } from "../../request/api";

const Catalog: React.FC = () => {
  const navigate = useNavigate();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      checkToken().then((res) => {
        if (res?.code === 1) {
          setIsReady(true);
        } else {
          navigate("/login");
        }
      });
    }
  }, []);

  return isReady ? (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <img
            className={styles.img}
            alt=""
            src={getPublicPath("/imgs/图片@2x.png")}
          />
          <span>案例库</span>
        </div>
        <div className={styles.main}>
          <div
            className={styles.card}
            onClick={() => {
              window.location.href =
                "https://zhishudalv.cn/anliku_nblg?token=" +
                localStorage.getItem("token");
            }}
          >
            <img src={getPublicPath("/imgs/catalog_1.png")} alt="" />
            <div className={styles.desc}>
              <span>浙大宁波理工学院</span>
              <span>浙江 · 宁波</span>
            </div>
          </div>
          <div
            className={styles.card}
            onClick={() => {
              window.location.href =
                "https://zhishudalv.cn/anliku?token=" +
                localStorage.getItem("token");
            }}
          >
            <img src={getPublicPath("/imgs/catalog_2.png")} alt="" />
            <div className={styles.desc}>
              <span>互动打卡功能演示</span>
              <span>浙江 · 宁波</span>
            </div>
          </div>
          <div
            className={styles.card}
            onClick={() => {
              window.location.href = "https://zhishudalv.cn/vr";
            }}
          >
            <img src={getPublicPath("/imgs/catalog_3.png")} alt="" />
            <div className={styles.desc}>
              <span>苏州图书馆（VR）</span>
              <span>江苏 · 苏州</span>
            </div>
          </div>
        </div>
        <span
          className={styles.logout}
          onClick={() => {
            Dialog.confirm({
              content: "退出登录",
              onConfirm: () => {
                localStorage.removeItem("token");
                navigate("/login");
              },
            });
          }}
        >
          退出登录
        </span>
        <img
          className={styles.bottom_img}
          src={getPublicPath("/imgs/山 Copy@2x.png")}
        />
      </div>
    </>
  ) : null;
};
export default Catalog;
