import { get, post } from ".";
import { CHECK_TOKEN, GET_LIST, LOGIN } from "./url";

export async function login(params: { account: string; password: string }) {
  const res = await post(LOGIN, params);
  return res;
}
export async function checkToken() {
  const res = await get(CHECK_TOKEN, null, true);
  return res;
}
export async function getList() {
  const res = await get(GET_LIST, null, true);
  return res;
}
