import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import AuthStore from "../../store/AuthStore";
import { useNavigate } from "react-router";
import { Dialog, Input, Switch, Toast } from "antd-mobile";
import { getPublicPath, joinClassNames } from "../../util";
import { login } from "../../request/api";
import md5 from "md5";

const Login: React.FC = () => {
  const navigate = useNavigate();

  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {}, []);

  const submit = () => {
    if (!account?.trim() || !password?.trim()) return;
    login({
      account: account.trim(),
      password: md5(password.trim()),
    }).then((res: any) => {
      if (res?.code === 1) {
        localStorage.setItem("token", res.data);
        Toast.show({
          icon: "success",
          content: "登录成功",
          maskClickable: false,
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        Toast.show({
          icon: "fail",
          content: res?.msg || "登录异常",
          maskClickable: false,
        });
      }
      console.log(res, "submit");
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img
          className={styles.img}
          alt=""
          src={getPublicPath("/imgs/图片@2x.png")}
        />
        <span>案例库</span>
      </div>
      <div className={styles.main}>
        <span className={styles.title}>登录</span>
        <div className={styles.form}>
          <div className={styles.row}>
            <span>账号</span>
            <div>
              <Input
                placeholder="ID:1"
                value={account}
                onChange={(v) => setAccount(v)}
                clearable
              />
            </div>
          </div>
          <div className={joinClassNames([styles.row, styles.password])}>
            <span>密码</span>
            <div>
              <Input
                type="password"
                placeholder="输入密码"
                value={password}
                onChange={(v) => setPassword(v)}
                clearable
              />
            </div>
          </div>
          <div className={styles.submit} onClick={submit}>
            登录
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
